import React from "react";

import { Link } from "gatsby";

import { Grid, Cell } from "baseui/layout-grid";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { HeadingLevel, Heading } from "baseui/heading";
import { Paragraph1 } from "baseui/typography";
import { StyledLink } from "baseui/link";

const NotFoundPage = () => (
  <Layout>
    <SEO title="Ошибка 404: Страница не найдена" />
    <Grid>
      <Cell span={12}>
        <HeadingLevel>
          <Heading>Ошибка 404: Страница не найдена</Heading>
          <Paragraph1>Здесь ничего нет.</Paragraph1>
          <Paragraph1>
            Начните с{" "}
            <StyledLink $as={Link} to="/">
              начала
            </StyledLink>{" "}
            или почитайте{" "}
            <StyledLink $as={Link} to="/news">
              новости
            </StyledLink>
            .
          </Paragraph1>
        </HeadingLevel>
      </Cell>
    </Grid>
  </Layout>
);

export default NotFoundPage;
